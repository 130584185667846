<template>
    <div
        class="profile-photo-layout-container"
        :class="{
            accepted: accepted,
            rejected: rejected,
        }"
    >
        <div class="profile-photo-layout">
            <div v-if="content.is_manner" class="manner-badge m-l-12 m-t-12">
                <img :src="require('@/assets/images/manner_like_badge.png')" class="manner-badge" alt="" />
            </div>
            <div v-if="content.is_michagam" class="manner-badge m-l-12 m-t-12">
                <img :src="require('@/assets/images/free-ticket-badge.svg')" class="free-ticket-badge" alt="" />
            </div>
            <div class="profile-wrapper">
                <div class="content-title" v-html="contentTitle" />
                <div class="content-message" :class="{ 'm-b-8': !accepted }" v-html="contentMessage" />
            </div>
            <div class="img-wrapper">
                <div class="img-title">
                    {{ targetNickname }}
                </div>
                <img v-if="accepted || expired || manner" class="status-badge" :src="badge" />
                <img
                    v-else-if="rejected"
                    class="status-badge"
                    :src="badge"
                    style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                />
                <img v-if="rejected || expired" class="src-img" :src="require('@/assets/images/profile_passed.png')" />
                <img
                    v-else-if="content.is_manner || content.is_michagam"
                    class="src-img"
                    :src="require('@/assets/images/profile_manner.png')"
                    alt=""
                />
                <img v-else class="src-img" :src="require('@/assets/images/profile_card.png')" />
            </div>
            <div class="btns">
                <button v-if="disabled" class="btn btn-profile disabled f-13" v-html="'프로필 확인하기'" />
                <button
                    v-else-if="content.is_manner || content.is_michagam"
                    @click="openProfile"
                    class="btn btn-profile btn-manner f-13"
                    v-html="'프로필 확인하기'"
                />
                <button v-else @click="openProfile" class="btn btn-profile f-13" v-html="'프로필 확인하기'" />
            </div>
            <div class="btns">
                <!-- <button v-if="disabled" class="btn btn-profile disabled f-13" v-html="'진행상황 확인하기'" /> -->
                <button @click="checkProgress" class="btn btn-profile btn-check f-13" v-html="'진행상황 확인하기'" />
            </div>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import userService from '@/services/user'

export default {
    name: 'ProfilePhotoLayout',
    props: ['message', 'profilePhotoMessage'],
    data: () => ({
        acceptStatus: null,
        timer: null,
        source: null,
        checkedProgress: false,
    }),
    watch: {
        content: {
            handler() {
                this.init()
            },
            deep: true,
        },
    },
    created() {},
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        images() {
            if (this.content.show_photo === false) return this.$blankProfile

            return this.content.urls
        },
        numImages() {
            return (this.images || []).length
        },
        region() {
            const { region } = this.content
            return `${this.$options.filters.noDetail(region) || '-'}`
        },
        targetNickname() {
            const { nickname } = this.content
            return `${this.$options.filters.noDetail(nickname) || '-'}`
        },
        badge() {
            if (this.expired) {
                return require('@/assets/images/badge_expired.png')
            } else {
                if (this.rejected) {
                    return require('@/assets/images/badge_passed.png')
                } else if (this.manner) {
                    return require('@/assets/images/badge_manner.png')
                } else {
                    return require('@/assets/images/badge_accepted.png')
                }
            }
        },
        chat() {
            return this.$store.getters.chat
        },
        agentId() {
            return this.chat.agent_id
        },
        opened() {
            return !!this.content.opened
        },
        accepted() {
            return this.acceptStatus === 'accepted'
            // return true
        },
        rejected() {
            return this.acceptStatus === 'rejected'
            // return true
        },
        manner() {
            return this.acceptStatus === 'manner'
        },
        expired() {
            const expireDate = this.$moment(this.message.created_at).add(14, 'days').format('YYYY-MM-DD')
            const today = this.$moment().format('YYYY-MM-DD')
            return today > expireDate
            // return true
        },
        disabled() {
            if (this.rejected || this.expired) {
                return true
            } else {
                return false
            }
        },
        acceptCTA() {
            return this.$translate('INTRODUCE_ME')
        },
        contentTitle() {
            if (this.$isTester()) {
                if (this.me.gender === 0) {
                    return '회원님을 만나고 싶어하는 회원입니다.'
                } else {
                    return '회원님에게 먼저 제공되는 프로필입니다.'
                }
            } else {
                if (this.content.source_accepted === 1) {
                    return `${this.me.name.substring(1)}님을 만나고 싶어하는 회원입니다.`
                } else {
                    return '좋은 사람 소개드려요.'
                }
            }
        },
        contentMessage() {
            if (this.$isTester()) {
                if (this.me.gender === 0) {
                    return '이미 회원님의 프로필을 보고 수락한 회원입니다.<br>회원님이 수락하시면 바로 만남을 잡아드립니다.'
                } else {
                    return '프라이버시 보호를 위해 회원님에게 프로필을 먼저 보여드리고, 회원님이 선택한 분에게만 회원님의 프로필을 보내드려요.'
                }
            } else {
                if (this.content.source_accepted === 1) {
                    return `이미 ${this.me.name.substring(
                        1
                    )}님의 프로필을 보고 수락한 회원입니다.<br>${this.me.name.substring(
                        1
                    )}님이 수락하시면 바로 만남을 잡아드립니다.`
                } else {
                    return '수락/패스 표시를 빨리 해주셔야 매칭 성공률이 높습니다.'
                }
            }
        },
        pastProfiles() {
            // const twoWeeksAgo = this.$moment(this.message.created_at).subtract(14, 'days').toDate()
            const twoWeeksAgo = this.$moment().subtract(14, 'days').toDate()
            let pastProfiles = this.profilePhotoMessage.filter(
                el =>
                    this.$moment(el.created_at).toDate() < this.$moment(this.message.created_at).toDate() &&
                    this.$moment(el.created_at).toDate() >= twoWeeksAgo
            )
            // console.log('111:', pastProfiles)
            const respondedProfile = this.$store.getters.respondedProfile || []
            for (let i = 0; i < respondedProfile.length; i++) {
                pastProfiles = pastProfiles.filter(el => el.id !== respondedProfile[i])
            }
            return pastProfiles
        },
        // isAbleToOpen() {
        //     if (this.pastProfiles.length > 0) {
        //         return false
        //     } else {
        //         return true
        //     }
        // },
        recentProfileDate() {
            const pastProfiles = this.pastProfiles
            if (pastProfiles.length === 0) {
                return ''
            }
            const sortedPastProfiles = pastProfiles.sort(
                (a, b) => this.$moment(a.created_at).toDate() - this.$moment(b.created_at).toDate()
            )
            return this.$moment(sortedPastProfiles[0].created_at).format('M월 D일 h시 m분')
        },
        itemHistories() {
            return this.source.photo_histories.item_histories
        },
        settings() {
            return this.$store.getters.settings || {}
        },
    },
    methods: {
        async init() {
            try {
                this.$loading(true)

                const { source_id } = this.content
                const source = await userService.detail(source_id)
                this.source = source
                this.acceptStatus = this.content.accept_status

                this.checkedProgress =
                    this.itemHistories.length > 0 &&
                    this.itemHistories.filter(o => o.code === 'check_progress').length > 0
                this.$loading(false)
            } catch (e) {
                this.$loading(false)
                console.error(e)
            }
        },
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        async checkProgress() {
            try {
                if (this.acceptStatus === 'accepted' || this.acceptStatus === 'manner') {
                    // console.log('this.content', this.content)
                    // console.log('this.acceptStatus', this.acceptStatus)
                    // console.log('this.settings', this.settings)
                    // console.log('this.message', this.message)
                    // console.log('this.profilePhotoMessage', this.profilePhotoMessage)
                    // console.log('this.source', this.source)
                    // console.log('this.me', this.me)
                    // console.log('this.me.products.find(item => (item.enabled === true && item.product_id === 210))', this.me.products.find(item => (item.enabled === true && item.product_id === 210)))
                    if (!this.me) {
                        return this.$modal.basic({
                            body: '유저 정보를 불러오는데 실패했습니다. 잠시후 다시 시도해주세요.',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }
                    if (!this.me.products.find(item => item.enabled === true && item.product_id === 210)) {
                        this.checkedProgress = true
                    } else if (!this.checkedProgress) {
                        const idx = await this.$modal.basic({
                            body: `${this.settings.point.check_progress} 포인트를 사용하여 매칭 진행 상황을 확인합니다. \n최초 1회에만 포인트가 사용되며 이후에는 무료로 진행상황을 확인할 수 있습니다.`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx) {
                            this.checkedProgress = true
                        } else {
                            return
                        }
                    }

                    const res = await chatService.checkProgress(this.chat.id, this.message.id)
                    let modalBody = '상태별 얼럿 내용'
                    modalBody = res.msg
                    console.log('modalBody', res.msg)
                    this.$modal.basic({
                        body: modalBody,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else if (this.rejected) {
                    this.$modal.basic({
                        body: '이미 패스한 프로필입니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else if (this.expired) {
                    this.$modal.basic({
                        body: '기간이 만료된 프로필입니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$modal.basic({
                        body: '프로필을 수락한 이후에 진행 상황을 조회할 수 있어요!',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
            } catch (e) {
                if (e.data.key === 'not_enough_point') {
                    const idx = await this.$modal.basic({
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-default',
                            },
                            {
                                label: '충전하러 가기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx) {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                } else {
                    this.$toast.error(e.data.msg)
                }
            }
        },
        async openProfile() {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            // if (!this.isAbleToOpen) {
            //     await this.$modal.basic({
            //         body: `수락/거절 표시를 하지 않은 프로필(<b>${this.recentProfileDate}</b> 발송된 프로필)이 있습니다.<br />수락/거절 표시 완료 후에 다음 프로필 조회가 가능합니다.`,
            //         buttons: [
            //             {
            //                 label: 'CONFIRM',
            //                 class: 'btn-primary',
            //             },
            //         ],
            //     })
            //     return
            // }

            if (this.me.unpaid_cancel_fee.length > 0) {
                this.$modal.basic({
                    body:
                        '납부하지 않은 약속 취소/미진행 수수료가 있습니다. 수수료를 납부한 이후에 다음 매칭을 진행할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }

            const photos = () => {
                let photos = []
                if (this.content.url) {
                    photos.push(this.content.url)
                }

                if (this.content.urls) {
                    photos = photos.concat(this.content.urls)
                }

                return photos.map(p => ({ url: p }))
            }

            await chatService.openProfile(this.chat.id, this.message.id)
            const content = this.$mustParse(this.message.content)
            content.opened = true
            this.message.content = JSON.stringify(content)

            this.$stackRouter.push({
                name: 'UserDetailPage',

                props: {
                    userId: this.content.source_id,
                    photos: photos(),
                    from: 'ProfilePhoto',
                    profilePhotoMessage: this.message,
                },
            })
        },
        async updateMessage(updateStatus) {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            try {
                await chatService.acceptProfile(this.chat.id, this.message.id, updateStatus)
                this.updateAccepted(updateStatus)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        reject() {
            this.updateMessage('rejected')
        },

        async onClickAccept() {
            const alert = await this.$modal.basic({
                body: '소개 받으시겠어요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (alert) {
                this.throttle(this.accept, 1000)
            }
        },
        accept() {
            // const alreadyReadHowToUseFeat = localStorage.getItem('how-to-use')
            // if (!alreadyReadHowToUseFeat) {
            //     this.$modal.custom({
            //         component: 'ModalHowToUseFeats',
            //         options: {
            //             preventCloseOnMousedownMask: true,
            //         },
            //     })
            //     localStorage.setItem('how-to-use', true)
            // }
            this.updateMessage('accepted')
        },
        undo() {
            this.updateMessage('undo')
        },
        updateAccepted(result) {
            const message = this.$store.getters.chat.$$messages.find(message => message.id === this.message.id)
            this.content.accept_status = result
            message.content = JSON.stringify(this.content)
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.profile-photo-layout-container {
    width: calc(100vw - 120px);
    max-width: 260px;
    // padding: 6px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(138, 116, 255, 0.05);

    // &.accepted {
    //     box-sizing: border-box;
    //     border: solid 1px $blue-primary;
    //     .response {
    //         font-size: 14px;
    //         color: $blue-primary;
    //         @include f-medium;
    //     }

    //     .content-title {
    //         font-size: 12px;
    //         margin: 0;
    //         color: $grey-08;
    //         @include f-regular;
    //     }

    //     &.isPremium {
    //         border: solid 1px $yellow-premium;

    //         .response {
    //             color: $yellow-premium;
    //         }
    //     }
    // }

    // &.rejected {
    //     .response {
    //         font-size: 14px;
    //         color: $grey-08;
    //     }
    // }
}

.premium-comment {
    border-top: 1px solid $grey-02;
    margin-top: 6px;
    padding-top: 6px;

    .title {
        color: $yellow-premium;
        font-size: 13px;
        @include f-medium;
    }
    .content {
        font-size: 15px;
        color: black;
        letter-spacing: -0.2px;
        @include f-regular;
    }
}

.profile-photo-layout {
    display: block;
    align-items: center;

    .manner-badge {
        width: 74px;
    }

    .free-ticket-badge {
        width: 65px;
    }

    .img-wrapper {
        position: relative;
        // width: 100%;
        height: 120px;
        // margin-right: 12px;
        margin: 0 12px 8px 12px;

        &.blurred::before {
            position: absolute;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            pointer-events: none;
            border-radius: 8px;
            content: '';
        }

        .src-img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            border: solid 1px $grey-02;
        }

        .img-title {
            width: 100%;
            position: absolute;
            padding: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 600;
            color: #181f39;
        }

        .status-badge {
            position: absolute;
            height: 12px;
            right: 8px;
            bottom: 8px;
        }
    }

    // .profile-wrapper {
    //     width: calc(100% - 92px);
    // }

    .content-title {
        margin: 12px 12px 4px 12px;
        color: #181f39;
        font-size: 12px;
        @include f-medium;
    }

    .content-message {
        color: #5c5c77;
        font-size: 10px;
        line-height: 15px;
        margin: 0 12px 8px 12px;
        // width: 100%;

        span {
            color: $grey-04;
        }
    }

    .btns {
        @include flex-row;

        .btn {
            height: 36px;
            padding: 0;
            margin: 0;
            font-size: 13px;
            border-radius: 6px;
            box-shadow: none;
            @include f-regular;
            @include center;
        }

        .btn.btn-profile {
            flex: 1 1 auto;
            margin: 0 12px 8px 12px;
            background-color: #151360;
            color: #fdfcfa;
            font-weight: 600;

            &.disabled {
                background-color: #ededed;
            }

            &.btn-manner {
                background-color: #ff7070;
            }
            &.btn-check {
                background-color: #ddd0da;
                color: #151360;
            }
        }
    }
}
</style>
